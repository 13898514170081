import React from 'react';
import { MenuItemContainer, Image, Tag, ProductName, Price } from './MenuItem.styles';

interface MenuItemProps {
    menuId: number;
    imageUrl: string;
    name: string;
    price: string;
    tag?: string;
    onItemSelect: (menuItem: MenuItemData) => void; // 클릭 시 데이터 전달
    explanation: string;
}

interface MenuItemData {
    menuId: number;
    imageUrl: string;
    name: string;
    price: string;
    tag?: string;
    explanation: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ menuId, imageUrl, name, price, tag, onItemSelect, explanation }) => {
    const handleClick = () => {
        // 클릭 시 해당 메뉴 데이터 전달
        onItemSelect({ menuId, imageUrl, name, price, tag, explanation });
    };

    return (
        <MenuItemContainer onClick={handleClick}> {/* 클릭 이벤트 처리 */}
            {tag && <Tag $tagType={tag}>{tag}</Tag>}
            <Image src={imageUrl} alt={name} />
            <ProductName>{name}</ProductName>
            <Price>{price}</Price>
        </MenuItemContainer>
    );
};

export default MenuItem;
