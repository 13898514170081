import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomePage, FindUsPage, MenuPage, OrderPage } from './pages';


const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/menu" element={<MenuPage />} />
                <Route path="/find-us" element={<FindUsPage />} />
                <Route path="/order" element={<OrderPage />} />
            </Routes>
        </Router>
    );
};

export default App;
