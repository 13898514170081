import React, { useEffect, useState } from 'react';
import { OverlayContainer, CloseButton, MenuItemDetail, HeaderSection, ImageContainer } from './MenuDetails.styles';
import { GrClose } from 'react-icons/gr';
import { Tag } from './MenuItem.styles'; // Tag 스타일 import

interface MenuDetailsProps {
    menuItem: any;
    onClose: () => void;
}

const MenuDetails: React.FC<MenuDetailsProps> = ({ menuItem, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // 컴포넌트가 마운트되면 애니메이션 시작
        setIsVisible(true);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {
            onClose(); // 애니메이션 시간이 끝난 후 실행
        }, 300); // 애니메이션 시간이 끝난 후 실행
    };

    return (
        <OverlayContainer className={isVisible ? 'open' : ''}>
            <CloseButton onClick={handleClose}>
                <GrClose size={28} />
            </CloseButton>
            <HeaderSection>
                <h2>{menuItem.name}</h2>
            </HeaderSection>
            <MenuItemDetail>
                <ImageContainer>
                    {menuItem.tag && <Tag $tagType={menuItem.tag}>{menuItem.tag}</Tag>}
                    <img src={menuItem.imageUrl} alt={menuItem.name} />
                </ImageContainer>
                <p>{menuItem.price}</p>
                <p>{menuItem.explanation}</p>
            </MenuItemDetail>
        </OverlayContainer>
    );
};

export default MenuDetails;
