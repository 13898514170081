import React from 'react';
import styled from 'styled-components';
import Layout from '../Layout'; // Layout import

const HeroSection = styled.section`
    background-image: url('https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-138018000000000000/banner_1677979917.jpg?size=medium'); /* 화려한 배경 이미지 */
    background-size: cover;
    background-position: center;
    height: 100vh; /* 전체 화면 높이 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 0 2rem;

    @media (max-width: 1000px) {
        height: 70vh; /* 모바일 버전에서는 높이 조정 */
    }
`;

const HeroText = styled.h1`
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1.5rem;

    @media (max-width: 1000px) {
        font-size: 2.5rem;
    }
`;

const SubText = styled.p`
    font-size: 1.5rem;
    margin-bottom: 2rem;

    @media (max-width: 1000px) {
        font-size: 1.2rem;
    }
`;

const OrderButton = styled.button`
    background-color: var(--hover-text-color);
    color: white;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }

    @media (max-width: 1000px) {
        padding: 0.8rem 1.5rem;
        font-size: 1.2rem;
    }
`;

const FeatureSection = styled.section`
    padding: 5rem 2rem;
    text-align: center;
    background-color: #f1f1f1;

    @media (max-width: 1000px) {
        padding: 3rem 1.5rem;
    }
`;

const FeatureTitle = styled.h2`
    font-size: 3rem;
    margin-bottom: 2rem;

    @media (max-width: 1000px) {
        font-size: 2.2rem;
    }
`;

const FeatureGrid = styled.div`
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    flex-wrap: wrap;
`;

const FeatureCard = styled.div`
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;

    @media (max-width: 1000px) {
        max-width: 100%;
    }
`;

const CardTitle = styled.h3`
    font-size: 1.8rem;
    margin-bottom: 1rem;
`;

const CardDescription = styled.p`
    font-size: 1rem;
    color: #777;
`;

const HomePage: React.FC = () => {
    return (
        <Layout>
            <HeroSection>
                <HeroText>Your Favorite Sandwiches</HeroText>
                <SubText>Delicious, fresh, and fast</SubText>
                <OrderButton>Start Your Order</OrderButton>
            </HeroSection>

            <FeatureSection>
                <FeatureTitle>Why Choose Us?</FeatureTitle>
                <FeatureGrid>
                    <FeatureCard>
                        <CardTitle>Fresh Ingredients</CardTitle>
                        <CardDescription>We use only the freshest ingredients to craft our delicious sandwiches.</CardDescription>
                    </FeatureCard>
                    <FeatureCard>
                        <CardTitle>Wide Variety</CardTitle>
                        <CardDescription>Explore our wide variety of subs, sides, and drinks to satisfy your cravings.</CardDescription>
                    </FeatureCard>
                    <FeatureCard>
                        <CardTitle>Hygiene First</CardTitle>
                        <CardDescription>We prioritize cleanliness and hygiene in every step of preparation, ensuring a safe and healthy meal.</CardDescription>
                    </FeatureCard>
                </FeatureGrid>
            </FeatureSection>
        </Layout>
    );
};

export default HomePage;
