import React from 'react';
import Navbar from '../components/Navbar'; // NavBar import
import Footer from '../components/Footer'; // Footer import
import styled from 'styled-components';

interface LayoutProps {
    children: React.ReactNode;
}

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* 전체 화면 높이를 차지 */
`;

const MainContent = styled.main`
    flex-grow: 1; /* 페이지의 컨텐츠가 차지하는 공간 */
`;

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <LayoutContainer>
            <Navbar /> {/* 상단 NavBar */}
            <MainContent>{children}</MainContent> {/* 페이지 컨텐츠 */}
            <Footer /> {/* 하단 Footer */}
        </LayoutContainer>
    );
};

export default Layout;
