import styled from 'styled-components';

export const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    
    img {
        width: 100%;
        height: 350px;
        object-fit: cover;
    }
`;

export const OverlayContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--secondary-bg-color); /* 전체 배경색 */
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    box-sizing: border-box;

    &.open {
        transform: translateX(0);
    }
    
    @media (min-width: 1000px) {
        width: 30%;
    }
`;

export const HeaderSection = styled.div`
    background-color: var(--primary-bg-color); /* 이미지 위 공간의 배경색 */
    padding: 20px;
    text-align: center;

    h2 {
        margin: 0;
        color: var(--primary-text-color);
    }
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 20px;
    right: 8px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: var(--primary-text-color); // 아이콘 색상
        transition: color 0.3s;

        &:hover {
            color: var(--hover-text-color); // 호버 시 색상 변경
        }
    }
`;


export const MenuItemDetail = styled.div`
    padding: 0; /* 이미지 위아래에 패딩이 없도록 설정 */
    text-align: center;
    background-color: var(--secondary-bg-color); /* 하단 부분은 전체와 동일한 배경색 */

    img {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        padding: 0; /* 이미지에 패딩 없음 */
    }

    p {
        margin-bottom: 0.5rem;
    }
`;
