import React from 'react';
import { FooterContainer, FooterInfo, FooterCopyright, FooterAddress } from './Footer.styles';

const Footer: React.FC = () => {
    return (
        <FooterContainer>
            <FooterInfo>
                <p>Contact: nidolight@icloud.com</p>
                <p>Phone: (512) 992-0089</p>
            <FooterAddress>Address: 922 Congress Ave., Austin, TX 78701</FooterAddress>
            </FooterInfo>
            <FooterCopyright>&copy; 2024 tasTeaDeli. All rights reserved.</FooterCopyright>
        </FooterContainer>
    );
};

export default Footer;
