// MenuItem.styles.ts
import styled from 'styled-components';

// 태그에 사용할 스타일 정의 (tagType을 직접 DOM에 전달하지 않음)
export const Tag = styled.div<{ $tagType: string }>`  // $로 시작하는 변수는 styled-components에서 DOM으로 전달되지 않음
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    padding: 0.3rem 0.7rem;
    border-radius: 5px;
    font-size: 1rem;
    color: ${({ $tagType }) => ($tagType === 'non-Diary' ? 'black' : 'white')};

    ${({ $tagType }) => {
        switch ($tagType) {
            case 'Best':
                return 'background-color: #FF5733;';
            case 'Vege':
                return 'background-color: #4CAF50;';
            case 'non-Diary':
                return 'background-color: #F5F5DC;';
            default:
                return 'background-color: #333;';
        }
    }}
`;

// 나머지 스타일 정의
export const MenuItemContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-snap-align: start;
    min-width: 200px;
    cursor: pointer; /* 클릭 가능한 요소임을 나타내는 커서 */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* 호버 효과를 위한 트랜지션 추가 */
    border-radius: 8px;
    
    &:hover {
        transform: scale(1.05); /* 살짝 확대 효과 */
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
    }
`;

export const Image = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
`;

export const ProductName = styled.h3`
    font-size: 1.2rem;
    margin: 0.6rem 0;
`;

export const Price = styled.p`
    font-size: 1rem;
    margin: 0.4rem 0;
    color: #777;
`;
