import React, { useEffect, useState, useRef } from 'react';
import Layout from '../Layout';
import { CategoryNavbar } from '../components';
import { MenuContainer } from "../containers";
import api from '../api/api';

interface MenuItem {
    menuId: number;
    imageUrl: string;
    name: string;
    price: string;
    tag: string;
    category: string;
    explanation: string;
}

const MenuPage: React.FC = () => {
    const [categories, setCategories] = useState<string[]>([]);
    const [menuData, setMenuData] = useState<MenuItem[]>([]);
    const [activeCategory, setActiveCategory] = useState('');
    const menuRefs = useRef<Record<string, HTMLDivElement | null>>({});

    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                console.log('API BASE URL:', process.env.REACT_APP_API_BASE_URL);
                const response = await api.get('/menu/all');
                const data = response.data;
                setMenuData(data);
                setCategories(Array.from(new Set(data.map((item: MenuItem) => item.category))));
            } catch (error) {
                console.error('Error fetching menu data:', error);
            }
        };

        fetchMenuData();
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const category = entry.target.getAttribute('data-category');
                        if (category) {
                            setActiveCategory(category);
                        }
                    }
                });
            },
            { threshold: 1 }
        );

        const currentRefs = menuRefs.current;

        categories.forEach((category) => {
            const section = currentRefs[category];
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            categories.forEach((category) => {
                const section = currentRefs[category];
                if (section) {
                    observer.unobserve(section);
                }
            });
        };
    }, [categories]);

    const scrollToCategory = (category: string) => {
        const target = menuRefs.current[category];
        if (target) {
            const navbarHeight = 167;
            const offsetTop = target.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
            window.scrollTo({ top: offsetTop, behavior: 'smooth' });
        }
    };

    return (
        <Layout>
            <CategoryNavbar categories={categories} activeCategory={activeCategory} onClickCategory={scrollToCategory} />
            {categories.map((category) => (
                <div
                    key={category}
                    ref={(el) => (menuRefs.current[category] = el)}
                    data-category={category}
                >
                    <MenuContainer
                        title={category}
                        menuData={menuData.filter(item => item.category === category)}
                    />
                </div>
            ))}
        </Layout>
    );
};

export default MenuPage;
